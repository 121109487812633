import { Link } from "react-router-dom";

// import { CTA } from "../components";
import { projects } from "../constants";
import { arrow } from "../assets/icons";

const Projects = () => {
  return (
    <section className='max-container'>
      <h1 className='head-text'>
        {/* My{" "} */}
        <span className='gradient_text drop-shadow font-semibold'>
        Projects
        </span>
      </h1>

      {/* <p className='text-slate-500 mt-2 leading-relaxed'> */}
      <p>
      I have hands-on experience with a diverse range of software technologies, including domain management with Namecheap, e-commerce platforms like Shopify, and design tools such as Figma. My technical expertise extends to coding with HTML, CSS, JavaScript, and React, as well as leveraging AI for innovative solutions. I utilize development environments like Replit and Visual Studio Code, manage projects through GitHub, and deploy websites seamlessly via Netlify.
      </p>

      <div className='item-container'>
        {projects.map((project) => (
          // <div className='lg:w-[400px] w-full' key={project.name}>
          <div className='item-container' key={project.name}>
            
            {/* <div className='block-container w-12 h-12'> */}
            {/* <div className={`btn-back rounded-xl ${project.theme}`} />
              <div className='btn-front rounded-xl flex justify-center items-center'>
                <img
                  src={project.iconUrl}
                  alt='threads'
                  className='w-1/2 h-1/2 object-contain'
                />
              </div> */}
            {/* </div> */}

            {/* <div className='img-project-container' key={project.name}>
              <div className={`btn-back rounded-xl ${project.theme}`} />
              <div className=''>
                <img
                  src={project.iconUrl}
                  className="h-full"
                />
              </div>
            </div> */}
            {/* <div className="portfolio__image__container">
                          <div className="portfolio__image__self">
                            <img src={project.iconUrl} alt="portfolio-items" />
                          </div>
                        </div> */}

            <div className='mt-5 flex flex-col'>
              <h4 className='text-2xl font-poppins font-semibold'>
                {project.name}
              </h4>
              Technologies: {project.Technologies}
              <p className='mt-2 text-slate-500'>{project.description}</p>
              <div className='mt-5 flex items-center gap-2 font-poppins'>
                <Link
                  to={project.link}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='font-semibold text-blue-600'
                >
                  Live Link
                </Link>
                <img
                  src={arrow}
                  alt='arrow'
                  className='w-4 h-4 object-contain'
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      <hr className='border-slate-200' />

      {/* <CTA /> */}
    </section>
  );
};

export default Projects;